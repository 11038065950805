import {baseChartOption, mergeObjects} from '@/libs/charts/main'

const dashedLineChartOption = {
    ...baseChartOption,
    chart: {
        type: 'line'
    },
    stroke: {
        width: 2,
        curve: 'straight'
        //dashArray: 5
    },
    yaxis: {
        labels: {
            formatter: (item) => {
                return `${(item / 1000000).toFixed(2)} €`
            }
        }
    }
}

function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(dashedLineChartOption, rawConfig)
    } else {
        return dashedLineChartOption
    }
}
export default {
    getConfig
}
