import {baseChartOption, mergeObjects} from '@/libs/charts/main'

const barChartOption = {
    ...baseChartOption,
    chart: {
        type: 'bar'
    },
    stroke: {
        curve: 'smooth'
    },
    yaxis: {
        labels: {
            formatter: (item) => {
                return `${(item / 1000000).toFixed(2)} €`
            }
        }
    }
}

function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(barChartOption, rawConfig)
    } else {
        return barChartOption
    }
}

export default {
    getConfig
}