import {baseChartOption, mergeObjects} from '@/libs/charts/main'
import i18n from '@/i18n/i18n'

const donutChartOption = {
    ...baseChartOption,
    chart: {
        type: 'donut'
    },
    plotOptions: {
        pie: {
            customScale: 0.85,
            donut: {
                labels: {
                    show: true,
                    total: {
                        show: true,
                        showAlways: true,
                        label: i18n.t('reporting.issued_bills.total'),
                        formatter: (item) => {
                            const sum = item.config.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                            return `${(sum / 1000000).toFixed(2)} €`
                        }
                    }
                }
            }
        }
    },
    xaxis: {
        categories: []
    },
    tooltip: {
        enabled: true,
        y: {
            formatter: (item, data) => {
                const sum = data.config.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                const calcItem = item / 1000000
                const procent = (calcItem / (sum / 1000000)) * 100
                return `${(calcItem).toFixed(2)} € (${procent.toFixed(2)} %)`
            }
        },
        fillSeriesColor: false
    },
    legend: {
        position: 'bottom'
    }
}

function getConfig(rawConfig) {
    if (rawConfig !== null) {
        return mergeObjects(donutChartOption, rawConfig)
    } else {
        return donutChartOption
    }
}
export default {
    getConfig
}