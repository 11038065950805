<template>
    <div>
        <b-card no-body class="height-200">
            <b-card-header class="pb-1 flex flex-nowrap">
                <b-card-title>{{$t('graphs.notifications')}}</b-card-title>
                <b-card-text class="font-small-2 mr-25 mb-0" style="word-break: break-word; line-height: 12px; text-align: end;">
                    {{$t('graphs.last_updated')}}: {{lastUpdatedFormated}}
                </b-card-text>
            </b-card-header>
            <b-card-body class="statistics-body">
                <div v-if="notifications.length > 0">
                    <div class="cursor-pointer item" v-for="(message,index) in notifications" :key="index" @click="viewMessage(message.id)">
                        <div class="d-flex align-items-center" :class="{'text-unread': !message.read}">
                            <div class="d-flex justify-content-center align-items-center" style="margin-right: 5px; border-radius: 50%; height: 28px; width: 28px;" :style="{backgroundColor: getPriority(message.priority).colour}">
                                <feather-icon size="17" class="icon-color" :class="{'text-unread': !message.read}" :icon="getPriority(message.priority).icon"/>
                            </div>
                            <div>
                                <div style="font-size: 13px; line-height: 13px;" :class="{'font-weight-bolder': !message.read}">{{message.title}}</div>
                                <div class="d-flex justify-content-start">
                                    <div class="d-flex justify-content-start align-items-center info" style="flex-wrap: wrap; line-height: 11px;">
                                        <div :class="{'font-weight-bolder': !message.read}" style="font-size: 10px; word-break: break-word;">{{getAuthor(message)}} | <feather-icon size="10" :icon="getType(message.type).icon"/> {{ getType(message.type).name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    {{ $t('print.message.no_notifications') }}
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
    require('dayjs/locale/sl')
    import {
        BCard,
        BCardText,
        BCardBody,
        BCardTitle,
        BCardHeader
    } from 'bootstrap-vue'
    import {getVariant, getPriority, getType, getAuthor} from '@/libs/Messages'

    export default {
        components: {
            BCardHeader,
            BCardTitle,
            BCardBody,
            BCardText,
            BCard
        },

        data() {
            return {
                lastUpdated: new Date(),
                getVariant,
                getPriority,
                getType,
                getAuthor
            }
        },

        watch: {
            //if user is on message list, this trigger reload for updating read on the list
            '$store.state.messages.unreadMessagesCount'() {
                this.loadData()
            }
        },

        computed: {
            lastUpdatedFormated() {
                return this.dayjs(this.lastUpdated).format('HH:mm')
            },
            isDark() {
                return !(this.$store.state.appConfig.layout.skin === 'dark')
            },
            notifications() {
                return this.$store.getters['messages/getLast5Messages'].slice(0, 3) ?? []
            }
        },

        methods: {
            async loadData() {
                this.lastUpdated = new Date()
            },
            viewMessage(id) {
                this.$router.push({name: 'system_message', params: {message_id: id}})
            }
        },

        async mounted() {
            this.lastUpdated = new Date()
        }
    }
</script>

<style scoped>
li {
    list-style: none;
}

.item {
    padding: 6px 0;
    border-radius: 4px;
}

.item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.text-unread {
    color: black!important;
}

.icon-color {
    color: #6e6b7b;
}

.dark-layout .icon-color{
    color: #b4b7bd;
}

.dark-layout .text-unread {
    color:white!important;
}
</style>